module.exports = {
  siteTitle: 'Isaiah Grey | Engineering Leader & Software Craftsman', // <title>
  manifestName: 'Identity',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#222',
  manifestThemeColor: '#222',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-identity/`, // This path is subpath of your hosting https://domain/portfolio
  authorName: 'Isaiah Grey',
  heading: `Engineering Leader & Software Craftsman`,
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/isaiahgrey93',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/isaiahgrey93',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://linkedin.com/in/isaiahgrey',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:isaiahgrey@gmail.com',
    },
  ],
};
